<template>
  <div>
    <ac-module-heading> PA Requests </ac-module-heading>
    <v-card class="card-wrapper">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center pb-5">
          <ac-select
            label="Company"
            v-model="selectedCompany"
            :items="companies"
            class="company-select me-3"
          />
        </div>
        <v-spacer />
        <div class="d-flex align-center pb-5">
          <ac-button title="Download CSV" :icon="icons.mdiDownload" class="me-3" />
          <ac-button title="Download PDF" :icon="icons.mdiDownload" class="me-3" />
          <ac-input
            v-model="searchQuery"
            placeholder="Search"
            outlined
            class="staff-list-search me-3"
          />
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="requestList"
        :options.sync="options"
        :server-items-length="totalRequestList"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.date`]="{ item: { date } }">
          <span>{{ formateDate(date) }}</span>
        </template>

        <template #[`item.actions`]>
          <ac-icon-btn
            btn-class="me-1"
            text="Approve / Reject"
            color="primary"
            :icon="icons.mdiShieldAlert"
          />
          <ac-icon-btn btn-class="me-1" text="View" color="success" :icon="icons.mdiEye" />
          <ac-icon-btn btn-class="me-1" text="Resend" color="secondary" :icon="icons.mdiSync" />
          <ac-icon-btn
            btn-class="me-1"
            text="Remove"
            color="error"
            :icon="icons.mdiDeleteOutline"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcInput from '@/components/AcInput.vue'
import AcSelect from '@/components/AcSelect.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import store from '@/store'
import padealingStoreModule from '@/store/modules/pa-dealing'
import { mdiDeleteOutline, mdiDownload, mdiEye, mdiShieldAlert, mdiSync } from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'
import useRequests from './composition-apis/requests'

export default {
  components: {
    AcButton,
    AcSelect,
    AcInput,
    AcIconBtn,
  },
  setup() {
    const PA_DEALING_STORE_MODULE_NAME = 'pa-dealing'

    // Register module
    if (!store.hasModule(PA_DEALING_STORE_MODULE_NAME)) {
      store.registerModule(PA_DEALING_STORE_MODULE_NAME, padealingStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PA_DEALING_STORE_MODULE_NAME))
        store.unregisterModule(PA_DEALING_STORE_MODULE_NAME)
    })

    const {
      headers,
      options,
      requestList,
      totalRequestList,
      searchQuery,
      companies,
      selectedCompany,
      loading,
      formateDate,
    } = useRequests()

    return {
      headers,
      options,
      requestList,
      totalRequestList,
      searchQuery,
      companies,
      selectedCompany,
      loading,
      formateDate,

      icons: {
        mdiDeleteOutline,
        mdiDownload,
        mdiEye,
        mdiShieldAlert,
        mdiSync,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  .company-select {
    max-width: 16rem;
  }
  .staff-list-search {
    max-width: 12rem;
  }
}
</style>
