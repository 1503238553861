import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import moment from 'moment-timezone'

export default function useRequests() {
  const requestList = ref([])
  const totalRequestList = ref(0)
  const loading = ref(false)
  const searchQuery = ref('')
  const isDialogVisible = ref(false)
  const companies = ref(['Company 1', 'Company 2', 'Company 3'])
  const selectedCompany = ref('')

  const headers = [
    { text: 'User Name', value: 'username' },
    { text: 'Date', value: 'date' },
    { text: 'Type Of Trade', value: 'rTypeOfTrade' },
    { text: 'Buy/Sell', value: 'buysell' },
    { text: 'GTC', value: 'gtc' },
    { text: 'Security', value: 'security' },
    { text: 'Status', value: 'status' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const fetchRequestList = () => {
    store
      .dispatch('pa-dealing/fetchRequestList', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(data => {
        requestList.value = data.docs
        totalRequestList.value = data.totalItems

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    fetchRequestList()
  })

  // *====================---*
  // *------- UI -------------------*
  // *====================---*

  const formateDate = date => {
    if (!date) return null
    return moment(date).format('DD MMM YYYY HH:MM:SS')
  }

  return {
    headers,
    options,
    requestList,
    totalRequestList,
    isDialogVisible,
    companies,
    selectedCompany,
    searchQuery,
    loading,
    formateDate,
  }
}
